const links = [
  {
    name: "Home",
    url: "#home",
  },
  {
    name: "clocks",
    url: "#clocks",
  }
];

export default links;
