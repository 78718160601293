import Home from "./pages/Home";
import Divider from "./components/Divider";
import Clocks from "./components/Clocks";
import Footer from "./components/Footer";

function App() {
  return (
    <div className="App">
      <Home />
      <Divider />
      <Clocks />
      <Divider />
      <Footer />
    </div>
  );
}

export default App;