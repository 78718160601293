import Image1 from "../images/clock-1.jpg";
import Image2 from "../images/clock-2.jpg";
import Image3 from "../images/clock-3.jpg";
import Image4 from "../images/clock-4.jpg";
import Image5 from "../images/clock-5.jpg";
import Image6 from "../images/clock-6.jpg";

const images = { Image1, Image2, Image3, Image4, Image5, Image6 };

export default images;
