import Icons from "./assets/images/icons";
import images from "./assets/images/images";

const { faZap, faPlug } = Icons;
const { Image1, Image2, Image3, Image4, Image5, Image6 } = images;

const clocks = [
  {
    id: 1,
    clock: "DGT North American Chess Clock and Game Timer - #000001",
    description:
      "16 contrast settings that adjusts for any lighting and quick-set options for the most popular controls.",
    image: Image1,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/f2557feb-2b4b-4128-ad15-cb91ad155568'
  },
  {
    id: 2,
    clock: "DGT 3000 Limited Edition Chess Timer Chess Clock Wooden Color - #000002",
    description:
      "DGT 3000 Limited Edition Chess Timer Chess Clock Wooden Color; Wood; 17.8 x 12.7 x 7.6 Centimeters",
    image: Image2,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/89cde0ef-9bfe-4e9a-bdfc-bdcf1d71c79a'
  },
  {
    id: 3,
    clock: "DGT Universal Chess Clock And Game Timer (Black) - #000003",
    description:
      "It runs on battery and the pack includes 1 AA battery. It has play and pause button along with volume rockers (0.2 Kilograms)",
    image: Image3,
    icon: faZap,
    link: 'https://commerce.coinbase.com/checkout/622c1739-ff07-4d95-a3d5-76bb3b836559'
  },
  {
    id: 4,
    clock: "UJEAVETTE Professional Analog Chess Clock (Black) - #000004",
    description:
      "UJEAVETTE Professional Analog Chess Clock (Black). The Timing Is Accurate, And The Daily Error Is No More Than 1 Second.",
    image: Image4,
    icon: faPlug,
    link: 'https://commerce.coinbase.com/checkout/9b36b67c-e0db-4a40-ba11-83afa4f7e918'
  },
  {
    id: 5,
    clock: "FHS 1pc Classic Analog Chess Clock (Yellow) - #000005",
    description:
      "This chess clock does not require batteries, is driven by a clockwork, and is easy to use, similar to the operation of a quartz clock.",
    image: Image5,
    icon: faPlug,
    link: 'https://commerce.coinbase.com/checkout/a3338e8d-1cd0-4519-9e52-8520086fb593'
  },
  {
    id: 6,
    clock: "Cusfull Classic Analog Chess Clock Medium (Black) - #000006",
    description:
      "Mechanical chess clock with a timing start button. No Battery Needed. Accurate timing, the daily error is no more than 1 second.",
    image: Image6,
    icon: faPlug,
    link: 'https://commerce.coinbase.com/checkout/44a913b1-ae42-4750-852c-e82eddc6332d'
  }
];

export default clocks;
